<template>
    <div class="container" style="display:flex;">
        
        <div style="width:40%;">
            标签列表
            <el-tree
                style="margin-top:20px;"
                ref="tree"
                    :data="tagList"
                    
                    node-key="id"
                    :expand-on-click-node="false"            
                    @node-click="handleItemClick"
                    :props="tagDefaultProps"
                    >
            </el-tree>
            <!-- :render-content="renderContent" -->
            <!-- show-checkbox -->
        </div>
        <div style="width:60%;">
            <div style="display:flex;flex-direction:column;align-item:center;">
                <div>当前节点 <span style="color: #0066ff;">{{curNode.label}}</span></div>
                <div style="display:flex;justify-content:center;">
                    <el-button v-if="curNode.label" style="margin-left:20px;" type="text" @click="onEdit(1)">修改当前节点</el-button>
                    <el-button v-if="curNode.label && curNode.level<2" style="margin-left:20px;" type="text" @click="onEdit(2)">新增子节点</el-button>
                    <el-button v-if="curNode.label" style="margin-left:20px;" type="text" @click="onEdit(3)">新增兄弟节点</el-button>
                    <el-button style="margin-left:20px;" type="text" @click="onEdit(4)">新增一级节点</el-button>
                    <el-button v-if="curNode.label" style="margin-left:20px;" type="text" @click="onEdit(5)">删除当前节点</el-button>
                    
                </div>
            </div>
        </div>
        <el-dialog title="新建/编辑标签" v-model="editDialogVisible">
            <el-form :model="editForm">
                <el-form-item label="标签名称" label-width="100px">
                    <el-input v-model="editForm.name" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div style="display:flex;justify-content:center;">
                <el-button @click="editDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="onSaveEvent">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import {Label_Save, Label_Delete} from "@/api/tag";
import {getToken} from "@/utils/auth";

    export default {
        name: "cart",
        components:{},
        data(){
            return {
                tagList:[],  
                curNode:{},
                // optFlag:0,
                editForm:{
                    id:0,
                    pId:0,
                    name:'',
                },
                editDialogVisible:false,

                tagDefaultProps: {
                    children: 'children',
                    label: 'label',
                    labelSimple:'labelSimple',
                    value: 'value',
                    pid:'pid',
                    level:0,
                },
                parentId:[],

                level1Id:0, //获得当前选中项第一级的Id
                level2Id:0, //获得当前选中项第二级的Id
            }
        },
        computed: {...mapGetters(['GetMediaTagList'])},

        mounted(){
            this.getTags();  
        },

        methods: {
            async onSaveEvent(){
                var userId = getToken();
                const {id,pId,name} = this.editForm;
                // if (this.optFlag==1){   //修改
                    await Label_Save({
							userId,
							id,		
                            pId,
                            name										
						}).then(()=>{
                            this.$message.success('保存成功');
                            this.getTags().then(()=>{
                                // console.log('aa',this.curNode.level)
                                // if (this.level1Id!==0){
                                //     this.$refs.tree.store.nodesMap[this.level1Id].expanded = true;
                                // }
                                // if (this.curNode.level==1){                                       
                                //     this.$refs.tree.store.nodesMap[pId].expanded = true;
                                // }
                                // if (this.curNode.level==2){
                                //     this.getParentPID(this.curNode);
                                //     // console.log('aa',this.level1Id,pId)
                                //     this.$refs.tree.store.nodesMap[this.level1Id].expanded = true;
                                //     this.$refs.tree.store.nodesMap[pId].expanded = true;
                                // }
                                
                                // this.curNode={};
                                this.dealExpand();
                                this.editDialogVisible=false;
                            })  
                            
                        })
                // }else{
                //     if (this.optFlag==1){   //新增子节点
                // }
            },

            async onEdit(flag){
                // if(!this.curNode.id){
                //     return;
                // }
                var userId = getToken();
                if (flag===5){                    
					this.$confirm('是否确认删除：'+this.curNode.label+',删除后资源已有的绑定关系将被取消, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async () => {
						await Label_Delete({
							userId,
							id:this.curNode.id,													
						}).then(()=>{
                            this.$message.success('删除成功');                            
                            this.getTags().then(()=>{
                                if (this.curNode.level===1){
                                    this.level1Id=this.curNode.pId;
                                }
                                if (this.curNode.level===2){
                                    this.getParentPID(this.curNode);
                                    this.level2Id=this.curNode.pId;
                                }
                                // console.log('aa',this.level1Id,this.level2Id)
                                this.dealExpand();
                            }) 
                            
                        })
						
						// this.$router.go(-1);
					})
                }else{
                    // this.optFlag=flag;
                    this.level1Id=0;
                    this.level2Id=0;
                    if(flag===1){   //修改
                        this.editForm.id=this.curNode.id;
                        this.editForm.name=this.curNode.labelSimple;
                        this.editForm.pId=this.curNode.pId;  
                        if (this.curNode.level===1){
                            this.level1Id=this.curNode.pId;
                        }                      
                        if (this.curNode.level===2){
                            this.getParentPID(this.curNode);
                            this.level2Id=this.curNode.pId;
                        }
                    }
                    if(flag===2){   //新增子节点
                        this.editForm.id=0;
                        this.editForm.name='';
                        this.editForm.pId=this.curNode.id;
                        if (this.curNode.level===0){
                            this.level1Id=this.curNode.id;
                        }
                        if (this.curNode.level===1){
                            this.level1Id=this.curNode.pId;
                            this.level2Id=this.curNode.id;
                        }
                    }
                    if(flag===3){   //新增兄弟节点
                        this.editForm.id=0;
                        this.editForm.name='';
                        this.editForm.pId=this.curNode.pId;
                        if (this.curNode.level===1){
                            this.level1Id=this.curNode.pId;
                        }
                        if (this.curNode.level===2){
                            this.getParentPID(this.curNode);
                            this.level2Id=this.curNode.pId;
                        }
                    }
                    if(flag===4){   //新增一级节点
                        this.editForm.id=0;
                        this.editForm.name='';
                        this.editForm.pId=0;
                    }
                    this.editDialogVisible=true;
                }
                
                
            },

            async getTags(){
                await this.$store.dispatch('operate/GetMediaTagList').then(()=>{
                    var obj1=[];
                    this.GetMediaTagList.forEach(ele1 => {
                        var obj1Tmp={};
                        obj1Tmp.id=ele1.Id;
                        obj1Tmp.label=ele1.Name;
                        obj1Tmp.labelSimple=ele1.Name;
                        obj1Tmp.pId=ele1.ParentId;
                        obj1Tmp.level=ele1.level;
                        obj1Tmp.children=[];
                        ele1.SubTag.forEach(ele2 => {
                            var obj2Tmp={};
                            obj2Tmp.id=ele2.Id;
                            obj2Tmp.label=ele2.Name;
                            obj2Tmp.labelSimple=ele2.Name;
                            obj2Tmp.pId=ele2.ParentId;
                            obj2Tmp.level=ele2.level;
                            obj2Tmp.children=[];
                            ele2.SubTag.forEach(ele3 => {
                                var obj3Tmp={};
                                obj3Tmp.id=ele3.Id;
                                if (ele3.description.length>0){
                                    obj3Tmp.label=ele3.Name+'('+ele3.description+')';
                                }else{
                                    obj3Tmp.label=ele3.Name;
                                }
                                obj3Tmp.labelSimple=ele3.Name;
                                obj3Tmp.pId=ele3.ParentId;
                                obj3Tmp.level=ele3.level;
                                obj2Tmp.children.push(obj3Tmp);
                            });
                            obj1Tmp.children.push(obj2Tmp)
                        });
                        obj1.push(obj1Tmp);
                    });
                    this.tagList = obj1;
                    // console.log('aa',this.tagList)
                });  
            },

            dealExpand(){
                if (this.level1Id>0){        
                    console.log('zhankai 1',this.level1Id)                               
                    this.$refs.tree.store.nodesMap[this.level1Id].expanded = true;
                }
                if (this.level2Id>0){             
                    console.log('zhankai 2',this.level2Id)                             
                    this.$refs.tree.store.nodesMap[this.level2Id].expanded = true;
                }
            },

            //获取父级的PID
            //应用于三级节点获取一级节点的ID
            getParentPID(item){
                this.level1Id=0;
                if (item.pId===0){
                    return;
                }
                var pId=item.pId;
                this.tagList.forEach(ele1 => {
                    if (ele1.children.length>0){
                        ele1.children.forEach(ele2 => {
                            if (ele2.id===pId){
                                this.level1Id=ele2.pId
                                return;
                            }
                        });
                    }
                    
                });
            },

            handleItemClick(item){
                console.log(item);
                this.curNode=item;
                    // this.departmentId = item.value;
                    // this.departmentPathName = generateDepartmentFinalNameArray({
                    //     departmentId: item.value,
                    //     departmentList: this.departmentList
                    // }).map(item => item.label).join(' / ');
                    // // console.log('kaishi ');
                    // await this.$store.dispatch('user/getUserDepartmentTargetList', {departmentId: item.value});
            },

            // append(data) {
            //     console.log('append')
            //     const newChild = { id: id++, label: 'testtest', children: [] };
            //     if (!data.children) {
            //     this.$set(data, 'children', []);
            //     }
            //     data.children.push(newChild);
            // },

            // remove(node, data) {
            //     console.log('append')
            //     const parent = node.parent;
            //     const children = parent.data.children || parent.data;
            //     const index = children.findIndex(d => d.id === data.id);
            //     children.splice(index, 1);
            // },


            // renderContent(h, { node, data, store }) {
            //     return (
            //     <span class="custom-tree-node">
            //         <span>{node.label}</span>
            //         <span>
            //         <el-button size="mini" type="text" on-click={ () => this.append(data) }>Append</el-button>
            //         <el-button size="mini" type="text" on-click={ () => this.remove(node, data) }>Delete</el-button>
            //         </span>
            //     </span>);
            // }
        }
    }
</script>

<style scoped>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
</style>